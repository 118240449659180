// import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import { HashRouter, Route, Routes, Link } from 'react-router-dom';
import { Layout,Menu } from 'antd';
const { Header, Sider, Content } = Layout;
import { HeatMapOutlined, BarsOutlined,FrownOutlined,SnippetsOutlined,BarcodeOutlined } from '@ant-design/icons';

import GoodList from './views/goodlist/goodlist.js';
import Weather from './views/weather/weather.js';
import Index from './views/index/index.jsx';
import Menus from './views/menus/menus.js';
import TemplateList from './views/templateList/templateList.jsx';
import AppletInfo from './views/appletInfo/appletInfo.jsx';
import PreambleOpe from './views/preambleOpe/preambleOpe.jsx';
import SetCard from './views/setCard/setCard.jsx';
import Login from './views/login/login.jsx';


class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLogin: false
    }
  }
  
  componentDidMount(){
    if(localStorage.getItem('authorization')){
      this.setState({
        isLogin: true
      })
    }
    
  }

  render(){
    return (
      <HashRouter>
        <div className="App">
          {
            this.state.isLogin ? 
              <Layout>
                <Header>服务商</Header>
                <Layout>
                  <Sider>
                    {/* <div><Link to="/index">草稿列表</Link></div>
                    <div><Link to="/templateList">模板列表</Link></div>
                    <div><Link to="/appletInfo">小程序管理</Link></div> */}
                    <Menu defaultSelectedKeys="index" theme="dark">
                      <Menu.Item key="setCard"><BarcodeOutlined /><Link to="/setCard">公众号管理</Link></Menu.Item>
                      <Menu.Item key="appletInfo"><FrownOutlined /><Link to="/appletInfo">小程序管理</Link></Menu.Item>
                      <Menu.Item key="index"><HeatMapOutlined /><Link to="/index">小程序草稿</Link></Menu.Item>
                      <Menu.Item key="templateList"><BarsOutlined /><Link to="/templateList">小程序模板</Link></Menu.Item>
                      <Menu.Item key="preambleOpe"><SnippetsOutlined /><Link to="/preambleOpe">第三方授权登录</Link></Menu.Item>
                    </Menu>
                  </Sider>
                  <Content>
                    <Routes>
                      <Route path="/" element={<Index/>}></Route>
                      {/* <Route path="/login" element={<Login/>}></Route> */}
                      <Route path="/templateList" element={<TemplateList/>}></Route>
                      <Route path="/index" element={<Index/>}></Route>
                      <Route path="/appletInfo" element={<AppletInfo/>}></Route>
                      <Route path="/preambleOpe" element={<PreambleOpe/>}></Route>
                      <Route path="/setCard" element={<SetCard/>}></Route>
                      <Route path="/menu" element={<Menus/>}>
                        <Route path="/menu/goodlist" element={<GoodList/>}></Route>
                        <Route path="/menu/weather" element={<Weather/>}></Route>
                      </Route>
                    </Routes>
                  </Content>
                </Layout>
              </Layout>
            :
              <Routes>
                <Route path="/" element={ <Login/> }></Route>
                <Route path="/login" element={ <Login/> }></Route>
              </Routes>
          }
        </div>
      </HashRouter>
    )
  }
}
export default App;
