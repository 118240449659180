import { Component } from "react";
import './login.scss';
import { Button, Form, Input } from 'antd';
import { loginByCode } from '../../api/tripar.js';
import withRouter from "../../withRouter/withRouter";
class Login extends Component{
  constructor(props){
    super(props)
  }

  onFinish(val){
    let _this = this;
    loginByCode(val).then(data => {
      console.log(data)
      if(data.authorization){
        localStorage.setItem('authorization', data.authorization)
        _this.props.navigate('/setCard',{state:{}})
        window.location.reload();
      }
    }).catch(err => {
      console.log(err)
    })
  }

  render(){
    return (
      <div className="login">
        <h1>请登录</h1>
        <Form name="basic" labelCol={{ span: 3, }}
          onFinish={(val) => this.onFinish(val)}
          autoComplete="off"
        >
          <Form.Item label="账号" name="account"
            rules={[
              {
                required: true,
                message: '请输入账号',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="密码" name="password"
            rules={[
              {
                required: true,
                message: '请输入密码',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block> 登录 </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
export default withRouter(Login);