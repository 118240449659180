import { Component } from "react";
class Weather extends Component{
  render(){
    return (
      <div>
        Weather
      </div>
    )
  }
}
export default Weather;