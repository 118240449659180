import { Component } from 'react'
import './templateList.less';
import { getTemplateList, delTemplate, updateTemplate } from '../../api/tripar.js';
import { Table,Button,message } from 'antd';

class TemplateList extends Component{
  constructor(props){
    super(props)
    this.state = {
      dataList: [],
      columns: [
        {
          title: '添加为模板时间',
          dataIndex: 'create_time',
          key: 'create_time',
          render: (record) => {
            return (
              <span>{this.timeToString(record*1000)}</span>
            )
          }
        },
        {
          title: '模板版本号',
          dataIndex: 'user_version',
          key: 'user_version',
        },
        {
          title: '模板描述',
          dataIndex: 'user_desc',
          key: 'user_desc',
        },
        {
          title: '模板 id',
          dataIndex: 'template_id',
          key: 'template_id',
        },
        {
          title: '模板类型',
          dataIndex: 'template_type',
          key: 'template_type',
          render: (record) => {
            return (
              <span>{record==0?'普通模板':'标准模板'}</span>
            )
          }
        },
        {
          title: '小程序的appid',
          dataIndex: 'source_miniprogram_appid',
          key: 'source_miniprogram_appid',
        },
        {
          title: '小程序的名称',
          dataIndex: 'source_miniprogram',
          key: 'source_miniprogram',
        },
        // {
        //   title: '标准模板的场景标签',
        //   dataIndex: 'audit_scene',
        //   key: 'audit_scene',
        // },
        // {
        //   title: '标准模板的审核状态',
        //   dataIndex: 'audit_status',
        //   key: 'audit_status',
        // },
        // {
        //   title: '标准模板的审核驳回的原因',
        //   dataIndex: 'reason',
        //   key: 'reason',
        // },
        {
          title: '操作',
          key: 'action',
          render: (record) => {
            return (
              <Button onClick={() => this.delTemp(record.template_id)}>删除</Button>
            )
          }
        }
      ]
    }
  }
  componentDidMount(){
    getTemplateList().then(data => {
      console.log(data)
      this.setState({
        dataList: data
      })
    }).catch(err => {
      console.log(err)
    })
  }
  timeToString(times){
    console.log(times)
    let data = new Date(times);
    let tens = (val) => {
      return val>9 ? val : '0'+val
    }
    let years = data.getFullYear();
    let months = tens(data.getMonth()+1);
    let days = tens(data.getDate());
    let hours = tens(data.getHours());
    let fz = tens(data.getMinutes());
    let mz = tens(data.getSeconds());
    return years + '-' + months + '-' + days + ' ' + hours + ':' + fz + ':' + mz;
  }
  //删除模板
  delTemp(ids){
    console.log(ids)
    delTemplate({
      template_id: ids
    }).then(data => {
      message.success(data.errmsg)
      setTimeout(() => {
        this.componentDidMount();
      },500)
    }).catch(err => {

    })
  }
  
  //更新草稿库
  updateTemplates(){
    console.log('----------1')
    updateTemplate().then(data => {
      console.log(data)
      message.success('更新成功')
      setTimeout(() => {
        this.componentDidMount();
      },500)
    }).catch(err => {
      console.log(err)
    })
  }

  render(){
    return (
      <div className='templateList_div'>
        <div className='top_div'>
          <Button type="primary" onClick={() => this.updateTemplates()}>更新模板库</Button>
        </div>
        <Table pagination={false} dataSource={this.state.dataList} columns={this.state.columns} rowKey={record => record.id} />
      </div>
    )
  }
}
export default TemplateList;