import { Component } from "react";
import './index.less';
import { Table,Button,message } from 'antd';
// import withRouter from "../../withRouter/withRouter";

import { getDraftList, addTemplate, updateDraft } from '../../api/tripar.js';

class Index extends Component{
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [
        // {
        //   create_time: 1234567890,
        //   created_at: "-0001-11-30 00:00:00",
        //   draft_id: 1,
        //   id: 1,
        //   updated_at: "-0001-11-30 00:00:00",
        //   user_desc: "萨达奥所多",
        //   user_version: "阿萨德"
        // }
      ],
      columns: [
        {
          title: '草稿微信id',
          dataIndex: 'draft_id',
          key: 'draft_id',
        },
        {
          title: '版本号',
          dataIndex: 'user_version',
          key: 'user_version',
        },
        {
          title: '版本描述',
          dataIndex: 'user_desc',
          key: 'user_desc',
        },
        {
          title: '开发者上传草稿时间',
          dataIndex: 'create_time',
          key: 'create_time',
          render: (record) => {
            return (
              <span>{this.timeToString(record*1000)}</span>
            )
          }
        },
        {
          title: '操作',
          key: 'action',
          render: (record) => (
            <div>
              <Button type="primary" onClick={() => this.addTo(record.draft_id)}>添加到模板库</Button>
            </div>
          )
        }
      ]
    };
  }
  componentDidMount(){
    console.log('-----------------')
    
    getDraftList().then(data => {
      console.log(data);
      this.setState({
        dataSource: data
      });
    }).catch(err => {
      console.log(err)
    })
  }
  
  timeToString(times){
    console.log(times)
    let data = new Date(times);
    let tens = (val) => {
      return val>9 ? val : '0'+val
    }
    let years = data.getFullYear();
    let months = tens(data.getMonth()+1);
    let days = tens(data.getDate());
    let hours = tens(data.getHours());
    let fz = tens(data.getMinutes());
    let mz = tens(data.getSeconds());
    return years + '-' + months + '-' + days + ' ' + hours + ':' + fz + ':' + mz;
  }
  //添加到模板库
  addTo = (ids) => {
    console.log(ids)
    addTemplate({
      draft_id: ids,
      template_type: 0
    }).then(data => {
      console.log(data)
      message.info(data.errmsg)
      setTimeout(() => {
        this.componentDidMount();
      },500)
    }).catch(err => {
      console.log(err)
    })
  }

  //更新草稿库
  updateDrafts(){
    console.log('----------1')
    updateDraft().then(data => {
      console.log(data)
      message.success('更新成功')
      setTimeout(() => {
        this.componentDidMount();
      },500)
    }).catch(err => {
      console.log(err)
    })
  }

  render(){

    // const onFinish = (values) => {
    //   console.log('Success:', values);
    // };
  
    return (
      <div className="index_div">
        <div className="top_div">
          <Button type="primary" onClick={() => this.updateDrafts()}>更新草稿库</Button>
        </div>
        <Table pagination={false} dataSource={this.state.dataSource} columns={this.state.columns} rowKey={record => record.id} />
      </div>
    )
  }
}
// export default withRouter(Index)
export default Index