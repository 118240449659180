import { Component } from 'react';
import  './appletInfo.less';
import { getAuthManageList,setServiceDomain,setBusinessDomain,submitCheck,
  releaseMini,getTemplateList,uploadCode,createTempMessage,applyInterface,setPrivacyProtect,getVersionInfo } from '../../api/tripar.js';
import { Table,Button,message,Modal,Input,Spin } from 'antd';
const { Column } = Table;
const { TextArea } = Input;
class AppletInfo extends Component{
  constructor(props){
    super(props)
    this.state = {
      totals: 0,
      page: 1,
      pageSize: 10,
      dataList: [],
      columns: [
        {
          title: '授权方appid',
          dataIndex: 'authorizer_appid',
          key: 'authorizer_appid'
        },
        {
          title: '原始id',
          dataIndex: 'user_name',
          key: 'user_name',
        },
        // {
        //   title: '授权刷新令牌',
        //   dataIndex: 'authorizer_refresh_token',
        //   key: 'authorizer_refresh_token',
        // },
        {
          title: '小程序昵称',
          dataIndex: 'nick_name',
          key: 'nick_name',
        },
        {
          title: '头像',
          dataIndex: 'head_img',
          key: 'head_img',
        },
        {
          title: '主体名称',
          dataIndex: 'principal_name',
          key: 'principal_name',
        },
        {
          title: '账号介绍',
          dataIndex: 'signature',
          key: 'signature',
        },
        {
          title: '二维码url',
          dataIndex: 'qrcode_url',
          key: 'qrcode_url',
        },
        {
          title: '帐号状态',
          dataIndex: 'account_status',
          key: 'account_status',
        },
        {
          title: '小程序注册方式',
          dataIndex: 'register_type',
          key: 'register_type',
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: '400px',
          render: (record) => {
            return (
              <div>
                <Button onClick={() => this.setServer(record.authorizer_appid)}>服务域名</Button>
                <Button onClick={() => this.setBusiness(record.authorizer_appid)}>业务域名</Button>
                <Button onClick={() => this.showModule(record.authorizer_appid,record.user_name)}>上传代码</Button>
                <Button onClick={() => this.submitChecks(record.authorizer_appid)}>提交审核</Button>
                <Button onClick={() => this.releaseMinis(record.authorizer_appid)}>发布</Button>
              </div>
            )
          }
        }
      ],
      isModalVisible: false,
      tempList: [],
      tempColumns: [
        {
          title: '模板版本号',
          dataIndex: 'user_version',
          key: 'user_version',
        },
        {
          title: '模板描述',
          dataIndex: 'user_desc',
          key: 'user_desc',
          ellipsis: true
        },
        {
          title: '模板 id',
          dataIndex: 'template_id',
          key: 'template_id',
        },
        {
          title: '模板类型',
          dataIndex: 'template_type',
          key: 'template_type',
          render: (record) => {
            return (
              <span>{record==0?'普通模板':'标准模板'}</span>
            )
          }
        },
        {
          title: '小程序的appid',
          dataIndex: 'source_miniprogram_appid',
          key: 'source_miniprogram_appid',
        },
        {
          title: '小程序的名称',
          dataIndex: 'source_miniprogram',
          key: 'source_miniprogram',
        }
      ],
      authorizerAppid: '',
      userName: '',
      extJson: '',
      checkTemp: {},
      showLoading: false,
      versionInfo: {
        exp_info: {},
        release_info: {}
      },
      reasons: ''
    }
  }

  componentDidMount(){
    this.setState({
      showLoading: true
    })
    this.getAuthManageLists();
  }

  getAuthManageLists(){
    getAuthManageList({
      page: this.state.page,
      page_size: this.state.pageSize
    }).then(data => {
      this.setState({
        dataList: data.data,
        totals: data.total,
        showLoading: false
      })
    }).catch(err => {
      console.log(err)
    })
  }

  //设置服务域名
  setServer(ids){
    this.setState({
      showLoading: true
    })
    setServiceDomain({
      authorizer_appid: ids
    }).then(data => {
      message.info('设置成功')
      setTimeout(()=>{
        this.getAuthManageLists();
      },500)
      // if(data.errcode==0){
      //   message.info('设置成功')
      // }
      // else{
      //   message.info(data.errmsg)
      // }
    }).catch(err => {
      console.log(err)
      this.setState({
        showLoading: false
      })
    })
  }

  //设置业务域名
  setBusiness(ids){
    this.setState({
      showLoading: true
    })
    setBusinessDomain({
      authorizer_appid: ids
    }).then(data => {
      message.info('设置成功')
      setTimeout(()=>{
        this.getAuthManageLists();
      },500)
      // if(data.errcode==0){
      //   message.info('设置成功')
      // }
      // else{
      //   message.info(data.errmsg)
      // }
    }).catch(err => {
      console.log(err)
      this.setState({
        showLoading: false
      })
    })
  }

  //提交审核
  submitChecks(ids){
    this.setState({
      showLoading: true
    })
    submitCheck({
      authorizer_appid: ids
    }).then(data => {
      message.info('提交成功')
      setTimeout(()=>{
        this.getAuthManageLists();
      },500)
    }).catch(err => {
      console.log(err)
      this.setState({
        showLoading: false
      })
    })
  }
  //发布
  releaseMinis(ids){
    this.setState({
      showLoading: true
    })
    releaseMini({
      authorizer_appid: ids
    }).then(data => {
      message.info('发布成功')
      setTimeout(()=>{
        this.getAuthManageLists();
      },500)
    }).catch(err => {
      console.log(err)
      this.setState({
        showLoading: false
      })
    })
  }
  //显示弹窗
  showModule(ids,names){
    this.setState(
      {
      tempList: [],
      isModalVisible: true
      },
      ()=>{
        getTemplateList().then(data => {
          console.log(data)
          this.setState({
            tempList: data,
            authorizerAppid: ids,
            userName: names
          })
        }).catch(err => {
          console.log(err)
        })
      }
    )
    
    // this.setState({
    //   isModalVisible: true
    // })
  }
  onChangeSelect(selectedRowKeys, selectedRows){
    console.log(selectedRowKeys, selectedRows)
    this.setState({
      checkTemp: {
        template_id: selectedRows[0].template_id,
        user_version: selectedRows[0].user_version,
        user_desc: selectedRows[0].user_desc
      }
    })
  }
  handleOk(){
    console.log('ok')
    if(!this.state.checkTemp.template_id){
      message.info('请选择需要上传的模板')
      return false;
    }
    // if(!this.state.extJson){
    //   message.info('请输入小程序ext.json配置文件数据')
    //   return false;
    // }
    let info = {
      extEnable: true,
      extAppid: this.state.authorizerAppid
    }
    this.setState({
      showLoading: true
    })
    uploadCode({
      authorizer_appid: this.state.authorizerAppid,
      template_id: this.state.checkTemp.template_id,
      ext_json: JSON.stringify(info),
      user_version: this.state.checkTemp.user_version,
      user_desc: this.state.checkTemp.user_desc,
      user_name: this.state.userName
    }).then(data => {
      message.info('上传成功')
      this.setState({
        isModalVisible: false,
        checkTemp: {}
      })
      setTimeout(()=>{
        this.getAuthManageLists();
      },500)
      // if(data.errcode==0){
      //   message.info('上传成功')
      //   this.setState({
      //     isModalVisible: false,
      //     checkTemp: {}
      //   })
      // }
      // else{
      //   message.info(data.errmsg)
      // }
    }).catch(err => {
      console.log(err)
      this.setState({
        showLoading: false
      })
    })
  }
  handleCancel(){
    this.setState({
      isModalVisible: false,
      authorizerAppid: '',
      userName: '',
      checkTemp: {},
      tempList: []
    })
  }
  areaInput(e){
    this.setState({
      extJson: e.target.value
    })
  }
  onChanges(page, pageSize){
    console.log(page, pageSize);
    this.setState(
      {
        page: page,
        showLoading: true
      },
      () => {
        this.getAuthManageLists();
      }
    )
  }
  
  createTemp(ids){
    this.setState({
      showLoading: true
    })
    createTempMessage({
      authorizer_appid: ids
    }).then(data => {
      message.info('创建成功')
      setTimeout(()=>{
        this.getAuthManageLists();
      },500)
    }).catch(err => {
      console.log(err)
      this.setState({
        showLoading: false
      })
    })
  }

  applyInterfaces(ids){
    this.setState({
      showLoading: true
    })
    let pids = '' + Math.trunc(Math.random()*8999+1000) + '1' + Math.trunc(Math.random()*8999+1000) + '3' + Math.trunc(Math.random()*8999+1000) + '5' + Math.trunc(Math.random()*8999+1000);
    applyInterface({
      authorizer_appid: ids,
      api_json: JSON.stringify({
        PID: pids,
        api_json:  [
          {
            "api_name":"wx.getLocation",
            "content":"用于为用户推荐就近的门店"
          }
          // {
          //   "api_name":"wx.getUserProfile",
          //   "content":"用于展示用户的会员信息"
          // }
          // {
          //   "api_name":"",
          //   "content":"为了方便用户快速创建订单"
          // }
        ]
      })
    }).then(data => {
      message.info('申请成功')
      setTimeout(()=>{
        this.getAuthManageLists();
      },500)
    }).catch(err => {
      console.log(err)
      this.setState({
        showLoading: false
      })
    })
  }
  
  setPrivacyProtects(ids){
    this.setState({
      showLoading: true
    })
    setPrivacyProtect({
      authorizer_appid: ids
    }).then(data => {
      message.info('设置成功')
      setTimeout(()=>{
        this.getAuthManageLists();
      },500)
    }).catch(err => {
      console.log(err)
      this.setState({
        showLoading: false
      })
    })
  }

  showEidtion(ids){
    this.setState({
      showLoading: true
    })
    getVersionInfo({
      authorizer_appid: ids
    }).then(data => {
      this.setState({
        showLoading: false,
        versionInfo: data
      })
    }).catch(err => {
      console.log(err)
      this.setState({
        showLoading: false
      })
    })
  }

  handleVer(){
    this.setState({
      versionInfo: {
        exp_info: {},
        release_info: {}
      }
    })
  }

  timeToString(times){
    let data = new Date(times);
    let tens = (val) => {
      return val>9 ? val : '0'+val
    }
    let years = data.getFullYear();
    let months = tens(data.getMonth()+1);
    let days = tens(data.getDate());
    let hours = tens(data.getHours());
    let fz = tens(data.getMinutes());
    let mz = tens(data.getSeconds());
    return years + '-' + months + '-' + days + ' ' + hours + ':' + fz + ':' + mz;
  }

  showStatus(val){
    this.setState({
      reasons: val
    })
  }

  render(){
    return (
      <div className='appletInfo'>
        {/* <Table dataSource={this.state.dataList} columns={this.state.columns} rowKey={record => record.id} scroll={{x:true}} />; */}
        
        <Table dataSource={this.state.dataList} rowKey={record => record.id} pagination={{ position:['bottomRight'], defaultCurrent: 1, total: this.state.totals, pageSize: this.state.pageSize, onChange: (page, pageSize)=>this.onChanges(page, pageSize) }}>
          <Column width={150} title="授权方appid" dataIndex="authorizer_appid" key="authorizer_appid" />
          <Column width={150} title="原始id" dataIndex="user_name" key="user_name" />
          {/* <Column width={150} title="授权刷新令牌" dataIndex="authorizer_refresh_token" key="authorizer_refresh_token" /> */}
          <Column width={200} title="小程序昵称" dataIndex="nick_name" key="nick_name" />
          <Column width={150} title="头像" dataIndex="head_img" key="head_img" />
          <Column width={150} title="主体名称" dataIndex="principal_name" key="principal_name" />
          <Column width={150} title="账号介绍" dataIndex="signature" key="signature" />
          <Column width={150} title="线上版二维码" dataIndex="qrcode_url" key="qrcode_url"
            render={ (record) => {
              return (
                <a href={record} target="_blank">点击查看图片</a>
              )
            }}
          />
          <Column width={150} title="体验版二维码" dataIndex="tiyan_qrcode_path" key="tiyan_qrcode_path"
            render={ (record) => {
              return (
                <div>
                  {
                    record ? <a href={record} target="_blank">点击查看图片</a> : <span></span>
                  }
                </div>
              )
            }}
          />
          {/* <Column width={150} title="帐号状态" dataIndex="account_status" key="account_status" />
          <Column width={150} title="小程序注册方式" dataIndex="register_type" key="register_type" /> */}
          {/* dataIndex="check_status" key="check_status" */}
          <Column width={150} title="审核状态"  
            render={
              (info) => {
                let record = info.check_status;
                let reasonss = info.reason;
                return (
                  <div>{record==-1?<span onClick={() => this.showStatus(reasonss)}>审核驳回</span>:''}{record==1?'审核中':''}{record==2?'审核通过':''}{record==3?'延迟审核':''}{record==4?'已发布':''}</div>
                )
              }
            }
          />
          <Column title="操作"  key="action"  fixed='right' width={350}
            render={ (record) => {
              return (
                <div>
                  <Button onClick={() => this.setServer(record.authorizer_appid)}>服务域名</Button>
                  <Button onClick={() => this.setBusiness(record.authorizer_appid)}>业务域名</Button>
                  <Button onClick={() => this.showModule(record.authorizer_appid,record.user_name)}>上传代码</Button>
                  <Button onClick={() => this.submitChecks(record.authorizer_appid)}>提交审核</Button>
                  {
                    record.check_status == 2 
                    ? <Button onClick={() => this.releaseMinis(record.authorizer_appid)}>发布</Button>
                    : <span></span>
                  }
                  <Button onClick={() => this.createTemp(record.authorizer_appid)}>创建模板消息</Button>
                  <Button onClick={() => this.applyInterfaces(record.authorizer_appid)}>申请隐私协议</Button>
                  <Button onClick={() => this.setPrivacyProtects(record.authorizer_appid)}>设置隐私协议</Button>
                  <Button onClick={() => this.showEidtion(record.authorizer_appid)}>版本查看</Button>
                </div>
              )
            }}
          />
        </Table>
        <Modal width={900} title="选择需要上传的模板" visible={this.state.isModalVisible} onOk={() => this.handleOk()} onCancel={() => this.handleCancel()} cancelText="取消" okText="确认">
          { this.state.tempList.length > 0 
            ? <div>
                <Table pagination={false} dataSource={this.state.tempList} columns={this.state.tempColumns} rowKey={record => record.id} rowSelection={{ type: 'radio',onChange: (num,arr) => this.onChangeSelect(num,arr)}} />
                {/* <TextArea placeholder="小程序ext_json配置文件" value={this.state.extJson} onChange={() => this.areaInput(event)}></TextArea> */}
              </div>
            : <div></div>
          }
        </Modal>
        <Modal width={900} title="版本信息" wrapClassName="version_div" visible={this.state.versionInfo.errmsg} onOk={() => this.handleVer()} onCancel={() => this.handleVer()} cancelText="取消" okText="确认">
          <h2>体验版</h2>
          <div>
            <span>时间：{this.timeToString(this.state.versionInfo.exp_info.exp_time*1000)}     </span>
            <span>版本号：{this.state.versionInfo.exp_info.exp_version}</span>
          </div>
          <div>{this.state.versionInfo.exp_info.exp_desc}</div>
          <h2>线上版</h2>
          <div>
            <span>时间：{this.timeToString(this.state.versionInfo.release_info.release_time)}     </span>
            <span>版本号：{this.state.versionInfo.release_info.release_version}</span>
          </div>
          <div>{this.state.versionInfo.release_info.release_desc}</div>
        </Modal>
        
        <Modal width={900} title="审核失败" wrapClassName="version_div" visible={this.state.reasons} onOk={() => this.setState({reasons: '' })} onCancel={() => this.setState({reasons: '' })} cancelText="取消" okText="确认">
          <p>{this.state.reasons}</p>
        </Modal>

        {
          this.state.showLoading
          ? <div className='load_div'><Spin tip="Loading..."></Spin></div>
          : <span></span>
        }
        
      </div>
    )
  }
}
export default AppletInfo;