import './setCard.scss';
import { Component } from 'react';
import { Form, Input, Button, message, Spin,Table,notification,Modal,Upload } from 'antd';
import { updateCard,setCard,getPublicList,getApplyDetail,createMemberCard,getCardList } from '../../api/tripar.js';
const { Column } = Table;
const { TextArea } = Input;
class SetCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      showLoading: false,
      validateMessages: {
        required: '${label}是必填的!'
      },
      dataList: [],
      showModal: false,
      authorizerAppid: '',
      picUrl: '',
      logoUrl: '',
      showCard: '', //是否展示会员卡列表
      cardList: []
    }
  }
  componentDidMount(){
    getPublicList({
      page: 1,
      page_size: 100
    }).then(data=>{
      console.log(data);
      this.setState({
        dataList: data.data
      })
    }).catch(err => {
      console.log(err)
    })
  }
  onFinish(values){
    console.log(values);
    this.setState({
      showLoading: true
    })
    updateCard(values).then(data => {
      return setCard(values)
    }).then(data => {
      this.setState({
        showLoading: false
      })
      message.info('设置成功')
    }).catch(err => {
      this.setState({
        showLoading: false
      })
    })
  }
  editCard(appid,cid){
    this.setState({
      showLoading: true
    })
    updateCard({
      public_appid: appid,
      card_id: cid
    }).then(data => {
      return setCard({
        public_appid: appid,
        card_id: cid
      })
    }).then(data => {
      this.setState({
        showLoading: false
      })
      message.info('设置成功')
    }).catch(err => {
      this.setState({
        showLoading: false
      })
    })
  }
  //审核查看
  getApplys(ids){
    getApplyDetail({
      authorizer_appid: ids
    }).then(data => {
      let msg = '';
      switch(data.status){
        case -1:
          msg = '审核不通过';
          break;
        case 0:
          msg = '待审核';
          break;
        case 1:
          msg = '审核通过';
          break;
        default :
          msg = '数据异常'
      }
      notification.open({
        message: msg,
        description:  data.refuse_reason,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    }).catch(err => {
      console.log(err)
    })
  }

  showModals(ids){
    this.setState({
      showModal: true,
      authorizerAppid: ids
    })
    // getToken({
    //   authorizer_appid: ids
    // }).then(data => {
    //   this.setState({
    //     authorizerAppid: data.access_token
    //   })
    // }).catch(err => {
    //   console.log(err)
    // })
  }

  handleOk(){
    this.setState({
      showModal: false
    })
  }

  handleCancel(){
    this.setState({
      showModal: false
    })
  }

  handleChange(files,num){
    let _this = this;
    let formdata = new FormData();
    formdata.append('file',files);
    formdata.append('authorizer_appid',this.state.authorizerAppid);
    let url = `https://service.platform.i-ktv.com/api/member/upload`;
    let xml = new window.XMLHttpRequest();
    xml.open('post',url,true)
    xml.send(formdata);
    xml.onreadystatechange = function() {
      if(xml.readyState==4 && xml.status==200){
        let infos = JSON.parse(xml.response);
        if(infos.return_code != 200){
          message.info(infos.return_msg);
          return false;
        }
        if(num == 1){
          _this.setState({
            picUrl: infos.data.img_url
          })
        }
        else{
          _this.setState({
            logoUrl: infos.data.img_url
          })
        }
        
      }
    }
    return false;
  }

  createFrom(val){
    console.log(val)
    if(this.state.logoUrl ==''){
      message.info('请把会员卡背景图或卡券商户logo上传完整');
      return false;
    }
    val['authorizer_appid'] = this.state.authorizerAppid;
    // val['background_pic_url'] = this.state.picUrl;
    val['logo_url'] = this.state.logoUrl;
    createMemberCard(val).then(data => {
      console.log(data);
      if(data.card_id){
        message.info('创建会员卡成功，等待审核')
      }
    }).catch(err => {
      console.log(err)
    })
  }

  showCards(name,appid){
    this.setState({
      showCard: name
    })
    getCardList({
      authorizer_appid: appid,
      page: 1,
      page_size: 10
    }).then(data => {
      console.log(data)
      this.setState({
        cardList: data.data
      })
    }).catch(err => {
      console.log(err)
    })
  }

  cancleCard(){
    this.setState({
      showCard: ''
    })
  }

  render() {
    return (
      <div className='setCard'>
        <Table pagination={false} dataSource={this.state.dataList} columns={this.state.columns} rowKey={record => record.id} >
          <Column width={150} title="小程序昵称" dataIndex="nick_name" key="nick_name" />
          <Column width={150} title="appid" dataIndex="authorizer_appid" key="authorizer_appid" />
          <Column width={150} title="头像" dataIndex="head_img" key="head_img"
            render={ (record) => {
              return (
                <a href={record} target="_blank">点击查看图片</a>
              )
            }}
          />
          <Column width={150} title="主体名称" dataIndex="principal_name" key="principal_name" />
          <Column width={150} title="线上二维码" dataIndex="qrcode_url" key="qrcode_url"
            render={ (record) => {
              return (
                <a href={record} target="_blank">点击查看图片</a>
              )
            }}
          />
          <Column width={150} title="体验版二维码" dataIndex="tiyan_qrcode_path" key="tiyan_qrcode_path"
            render={ (record) => {
              return (
                <a href={record} target="_blank">点击查看图片</a>
              )
            }}
          />
          {/* <Column width={150} title="小程序注册方式		" dataIndex="register_type" key="register_type" /> */}
          <Column width={150} title="审核状态" dataIndex="check_status" key="check_status" 
            render={
              (record) => {
                return (
                  <div>{record==-2?'审核已被覆盖':''}{record==-1?'审核驳回':''}{record==1?'审核中':''}{record==2?'审核通过':''}{record==3?'延迟审核':''}{record==4?'已发布':''}</div>
                )
              }
            }
          />
          <Column width={150} title="账号类型" dataIndex="account_type" key="account_type" 
            render={
              (record) => {
                return (
                  <div>{record==1?'小程序':''}{record==2?'公众号':''}</div>
                )
              }
            }
          />
          <Column title="操作"  key="action"  fixed='right' width={350}
            render={ (record) => {
              return (
                <div>
                  <Button onClick={() => this.showModals(record.authorizer_appid)}>创建会员卡</Button>
                  <Button className='marbtn' onClick={() => this.showCards(record.nick_name,record.authorizer_appid)}>查看会员卡</Button>
                  <Button onClick={() => this.getApplys(record.authorizer_appid)}>审核查看</Button>
                </div>
              )
            }}
          />
        </Table>

        <Modal title="创建会员卡" visible={this.state.showModal} onOk={() => this.handleOk()} onCancel={() => this.handleCancel()} width="1000px" footer={null}>
          <Form name="nest-messages" validateMessages={this.state.validateMessages}  layout="vertical" onFinish={(values) => this.createFrom(values)}>
            <Form.Item  name='brand_name' label="商户名字"  rules={[ { required: true } ]} >
              <Input size="large" maxLength={12} placeholder='字数上限为12个汉字' />
            </Form.Item>
            <Form.Item name='title' label="卡券名" rules={[ { required: true } ]}>
              <Input size="large" maxLength={9} placeholder='字数上限为9个汉字' />
            </Form.Item>
            <Form.Item  name='color' label="券颜色" rules={[ { required: true } ]} initialValue='Color010'>
              <Input size="large" placeholder='按色彩规范标注填写Color010-Color100'  />
            </Form.Item>
            <Form.Item  name='notice' label="卡券使用提醒" rules={[ { required: true } ]} initialValue="会员卡特权，请以小程序展示为准">
              <Input size="large" placeholder='字数上限为16个汉字' />
            </Form.Item>
            <Form.Item  name='description' label="卡券使用说明" rules={[ { required: true } ]} initialValue="会员卡特权，请以小程序展示为准">
              <TextArea rows={4} placeholder='字数上限为1024个汉字' />
            </Form.Item>
            <Form.Item  name='prerogative' label="特权说明" rules={[ { required: true } ]} initialValue="会员卡特权，请以小程序展示为准">
              <TextArea rows={4} placeholder='限制1024汉字'  />
            </Form.Item>
            <Form.Item  name='bonus_cleared' label="积分清零规则" rules={[ { required: true } ]} initialValue="会员卡特权，请以小程序展示为准">
              <TextArea rows={4} placeholder=''  />
            </Form.Item>
            <Form.Item  name='bonus_rules' label="积分规则" rules={[ { required: true } ]} initialValue="会员卡特权，请以小程序展示为准">
              <TextArea rows={4} placeholder=''  />
            </Form.Item>
            <Form.Item  name='cost_money_unit' label="消费金额" rules={[ { required: true } ]} initialValue='100000'>
              <Input size="large"  placeholder='以分为单位' />
            </Form.Item>
            <Form.Item  name='increase_bonus' label="对应增加的积分" rules={[ { required: true } ]} initialValue="1">
              <Input size="large" placeholder='' />
            </Form.Item>
            <Form.Item  name='max_increase_bonus' label="用户单次可获取的积分上限" rules={[ { required: true } ]} initialValue='1'>
              <Input size="large" placeholder='' />
            </Form.Item>
            <Form.Item  name='init_increase_bonus' label="初始设置积分" rules={[ { required: true } ]} initialValue='0'>
              <Input size="large" placeholder='' />
            </Form.Item>
            <Form.Item  name='cost_bonus_unit' label="每使用 X 积分" rules={[ { required: true } ]} initialValue='1000'>
              <Input size="large" placeholder='' />
            </Form.Item>
            <Form.Item  name='reduce_money' label="抵扣 xx 元(分)" rules={[ { required: true } ]} initialValue='100'>
              <Input size="large" placeholder='以分为单位' />
            </Form.Item>
            <Form.Item  name='least_money_to_use_bonus' label="抵扣条件，满 xx 元可用" rules={[ { required: true } ]} initialValue='100000' >
              <Input size="large" placeholder='满 xx 元（这里以分为单位）可用' />
            </Form.Item>
            <Form.Item  name='max_reduce_bonus' label="抵扣条件，单笔最多使用 xx 积分" rules={[ { required: true } ]} initialValue='1'>
              <Input size="large" placeholder=''  />
            </Form.Item>
            {/* <Form.Item  label="会员卡背景图url" >
              {
                this.state.picUrl ? 
                <img className='picUrl_img' src={this.state.picUrl} />
                :
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action=""
                  // beforeUpload={(files) => this.beforeUpload(files)}
                  beforeUpload={(files) => this.handleChange(files,1)}
                >
                  上传
                </Upload>
              }
            </Form.Item> */}
            <Form.Item  label="卡券的商户logo" rules={[ { required: true } ]}>
              {
                this.state.logoUrl ? 
                <img className='picUrl_img' src={this.state.logoUrl} />
                :
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action=""
                  // beforeUpload={(files) => this.beforeUpload(files)}
                  beforeUpload={(files) => this.handleChange(files,2)}
                >
                  上传
                </Upload>
              }
            </Form.Item>
            <div>
            <Button type="primary" htmlType="submit">提交</Button>
            </div>
          </Form>
        </Modal>
        
        {/* <Form name="nest-messages" onFinish={(values) => this.onFinish(values)} validateMessages={this.state.validateMessages}  labelCol={{ span: 3, }}>
          <Form.Item
            name='public_appid'
            label="公众号appid"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name='public_appsecret'
            label="公众号secret"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name='card_id'
            label="会员卡id"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <div className='btn_div'>
            <Button type="primary" block htmlType="submit" size='large'>提交</Button>
          </div>
        </Form> */}

        {/* 会员卡列表 */}
        <Modal title={this.state.showCard} visible={this.state.showCard} onCancel={() => this.cancleCard()} width="1000px" footer={null}>
          <Table dataSource={this.state.cardList} rowKey={record => record.id} pagination={false}>
            <Column width={150} title="会员卡id" dataIndex="card_id" key="card_id" />
            <Column width={150} title="商户名称" dataIndex="brand_name" key="brand_name" />
            <Column width={150} title="会员卡名称" dataIndex="title" key="title" />
            <Column width={150} title="会员卡状态" dataIndex="status" key="status"
              render={ (record) => {
                return (
                  <div>{record==-1?'审核不通过':''}{record==0?'待审核':''}{record==1?'审核通过':''}</div>
                )
              }}
            />
            <Column width={150} title="原因" dataIndex="refuse_reason" key="refuse_reason" />
            <Column title="操作"  key="action"  fixed='right' width={350}
              render={ (record) => {
                return (
                  <div>
                    <Button onClick={() => this.editCard(record.authorizer_appid,record.card_id)}>更新会员卡</Button>
                  </div>
                )
              }}
            />
          </Table>
        </Modal>

        {
          this.state.showLoading
          ? <div className='load_div'><Spin tip="Loading..."></Spin></div>
          : <span></span>
        }
      </div>
    )
  }
}
export default SetCard;