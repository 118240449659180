import { Component } from 'react';
import { buildAuthUrl } from '../../api/tripar.js';
import './preambleOpe.less';
class PreambleOpe extends Component{
  constructor(props){
    super(props);
    this.state = {
      urls: ''
    }
  }
  componentDidMount(){
    buildAuthUrl().then(data => {
      console.log(data)
      this.setState({
        urls: data.auth_url
      })
    }).catch(err => {

    })
  }
  render(){
    return (
      <div className='preambleOpe'>
        <a href={this.state.urls} target="_blank">点我啊</a>
      </div>
    )
  }
}
export default PreambleOpe;