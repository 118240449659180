import React,{ Component } from "react";
import withRouter from "../../withRouter/withRouter";
class GoodList extends Component {
  componentDidMount(){
    console.log('-----------------')
  }
  render(){
    console.log(this.props.location)
    return (
      <div>
        GoodList
      </div>
    )
  }
}
export default withRouter(GoodList);