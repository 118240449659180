import { Component } from "react";
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';
const { Header, Sider, Content } = Layout;

class means extends Component{
  render(){
    return (
      <div>
        <Layout>
            <Header>Header</Header>
            <Layout>
              <Sider>Sider</Sider>
              <Content>
              <Outlet/>
              </Content>
            </Layout>
          </Layout>
        
      </div>
    )
  }
}
export default means;