import axios from 'axios';
import { message } from 'antd';

let xhr = (obj) => {
  return new Promise((resolve,reject) => {
    axios({
      baseURL: 'https://service.platform.i-ktv.com',
      method: obj.method || 'GET',
      url: obj.url,
      headers: {
        'authorization': localStorage.getItem('authorization') || ''
      },
      data: obj.data || {},
      params: obj.params || {}
    }).then(data => {
      if(data.data.return_code == 200){
        resolve(data.data.data);
      }
      else if(data.data.return_code == 403){
        localStorage.removeItem('authorization');
        location.href = location.origin + '/#/login';
        window.location.reload();
        // resolve(data.data.data);
      }
      else{
        message.info(data.data.return_msg)
        reject(data.data)
      }
    })
  })
  
}

//更新草稿库
export let updateDraft = () => {
  return xhr({
    url: '/admin/draft/updateDraft',
    method: 'get'
  })
}

//获取草稿列表
export let getDraftList = () => {
  return xhr({
    url: '/admin/draft/getDraftList',
    method: 'get'
  })
}

//添加草稿到模板库
export let addTemplate = (data) => {
  return xhr({
    url: '/admin/draft/addTemplate',
    method: 'POST',
    data: data
  })
}

//更新模板库
export let updateTemplate = () => {
  return xhr({
    url: '/admin/temp/updateTemplate',
    method: 'get'
  })
}

//获取模板列表
export let getTemplateList = () => {
  return xhr({
    url: '/admin/temp/getTemplateList',
    method: 'get'
  })
}

//删除模板
export let delTemplate = (data) => {
  return xhr({
    url: '/admin/temp/delTemplate',
    method: 'post',
    data: data
  })
}

//获取授权小程序列表
export let getAuthManageList = (params) => {
  return xhr({
    url: '/admin/manage/getAuthManageList',
    method: 'get',
    params: params
  })
}

//设置小程序服务域名
export let setServiceDomain = (data) => {
  return xhr({
    url: '/admin/manage/setServiceDomain',
    method: 'POST',
    data: data
  })
}

//设置小程序业务域名
export let setBusinessDomain = (data) => {
  return xhr({
    url: '/admin/manage/setBusinessDomain',
    method: 'POST',
    data: data
  })
}

//提交审核
export let submitCheck = (data) => {
  return xhr({
    url: '/admin/manage/submitCheck',
    method: 'POST',
    data: data
  })
}

//发布已通过审核的小程序
export let releaseMini = (data) => {
  return xhr({
    url: '/admin/manage/releaseMini',
    method: 'POST',
    data: data
  })
}

//上传代码到对应授权小程序
export let uploadCode = (data) => {
  return xhr({
    url: '/admin/manage/uploadCode',
    method: 'POST',
    data: data
  })
}

//构建授权链接
export let buildAuthUrl = () => {
  return xhr({
    url: '/api/common/buildAuthUrl',
    method: 'GET'
  })
}

//创建模板消息接口
export let createTempMessage = (data) => {
  return xhr({
    url: '/admin/manage/createTempMessage',
    method: 'POST',
    data: data
  })
}

//更新会员卡
export let updateCard = (data) => {
  return xhr({
    url: '/admin/public/updateCard',
    method: 'POST',
    data: data
  })
}

//设置会员卡
export let setCard = (data) => {
  return xhr({
    url: '/admin/public/setCard',
    method: 'POST',
    data: data
  })
}

//申请接口
export let applyInterface = (data) => {
  return xhr({
    url: '/admin/manage/applyInterface',
    method: 'POST',
    data: data
  })
}

//设置小程序用户隐私保护指引
export let setPrivacyProtect = (data) => {
  return xhr({
    url: '/admin/manage/setPrivacyProtect',
    method: 'POST',
    data: data
  })
}

//获取授权公众号列表
export let getPublicList = (params) => {
  return xhr({
    url: '/admin/manage/getPublicList',
    method: 'GET',
    params: params
  })
}

//获取公众号会员卡审核详情
export let getApplyDetail = (params) => {
  return xhr({
    url: '/api/member/getApplyDetail',
    method: 'GET',
    params: params
  })
}

//创建公众号会员卡
export let createMemberCard = (data) => {
  return xhr({
    url: '/api/member/createMemberCard',
    method: 'POST',
    data: data
  })
}
//获取授权公众号会员卡列表列表
export let getCardList = (data) => {
  return xhr({
    url: '/admin/manage/getCardList',
    method: 'POST',
    data: data
  })
}

//获取授权公众号会员卡列表列表
export let getVersionInfo = (data) => {
  return xhr({
    url: '/admin/manage/getVersionInfo',
    method: 'POST',
    data: data
  })
}

//用户登录
export let loginByCode = (data) => {
  return xhr({
    url: '/admin/login/loginByCode',
    method: 'POST',
    data: data
  })
}